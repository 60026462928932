// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-60316-heroesfrankfurt-js": () => import("./../../../src/pages/60316_heroesfrankfurt.js" /* webpackChunkName: "component---src-pages-60316-heroesfrankfurt-js" */),
  "component---src-pages-61440-asiaminh-js": () => import("./../../../src/pages/61440_asiaminh.js" /* webpackChunkName: "component---src-pages-61440-asiaminh-js" */),
  "component---src-pages-61440-mrle-js": () => import("./../../../src/pages/61440_mrle.js" /* webpackChunkName: "component---src-pages-61440-mrle-js" */),
  "component---src-pages-61440-primavera-js": () => import("./../../../src/pages/61440_primavera.js" /* webpackChunkName: "component---src-pages-61440-primavera-js" */),
  "component---src-pages-61440-zaiqa-js": () => import("./../../../src/pages/61440_zaiqa.js" /* webpackChunkName: "component---src-pages-61440-zaiqa-js" */),
  "component---src-pages-65520-bacco-js": () => import("./../../../src/pages/65520_bacco.js" /* webpackChunkName: "component---src-pages-65520-bacco-js" */),
  "component---src-pages-65520-camberger-restaurant-js": () => import("./../../../src/pages/65520_camberger-restaurant.js" /* webpackChunkName: "component---src-pages-65520-camberger-restaurant-js" */),
  "component---src-pages-65520-datoni-js": () => import("./../../../src/pages/65520_datoni.js" /* webpackChunkName: "component---src-pages-65520-datoni-js" */),
  "component---src-pages-65582-la-sicilia-js": () => import("./../../../src/pages/65582_la-sicilia.js" /* webpackChunkName: "component---src-pages-65582-la-sicilia-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-damichele-kelsterbach-js": () => import("./../../../src/pages/damichele-kelsterbach.js" /* webpackChunkName: "component---src-pages-damichele-kelsterbach-js" */),
  "component---src-pages-data-policy-js": () => import("./../../../src/pages/dataPolicy.js" /* webpackChunkName: "component---src-pages-data-policy-js" */),
  "component---src-pages-heroesburgerffm-js": () => import("./../../../src/pages/heroesburgerffm.js" /* webpackChunkName: "component---src-pages-heroesburgerffm-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-restaurants-index-js": () => import("./../../../src/pages/restaurants/index.js" /* webpackChunkName: "component---src-pages-restaurants-index-js" */),
  "component---src-pages-restaurants-redirect-js": () => import("./../../../src/pages/restaurants/redirect.js" /* webpackChunkName: "component---src-pages-restaurants-redirect-js" */)
}

